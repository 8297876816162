import {inject, observable} from 'aurelia-framework';

import {PatientService} from 'resources/services/PatientService';
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";

@inject(PatientService)
export class PatientCurve {
    @observable patient;
    patientService: PatientService;
    encounterId: string = undefined;

    constructor(patientService: PatientService) {
        this.patientService = patientService;
    }

    async activate(params) {
        this.encounterId = params.id;
    }

    async attached() {
        this.patient = await this.patientService.fetch(this.encounterId);

        document.body.style.background = 'white'
        RuntimeInfo.TogglePatientListLG(true, true);
    }
}
